import jwtDefaultConfig from './@core/auth/jwt/jwtDefaultConfig'
const baseURL = process.env.VUE_APP_FRONT_END_BASE_URL

window.SuperApp = {}
window.SuperApp.google = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT,
}
window.SuperApp.cache = {}
window.SuperApp.defaultImage = 'https://devapi.momitnow.com/storage/1/blog/d917c28045ad006b2b1d5f0458080adcb854344b.webp'
window.SuperApp.zoomed = false
window.SuperApp.walletEnabled = true
window.SuperApp.errorEnabled = true
window.SuperApp.mouseWheelToZoom = false
window.SuperApp.validations = {
  isUserDetailValid() {
    if (window.SuperApp.getters.isUser()) {
      const force_subscribe = localStorage.getItem('userforce_subscribe') === 'true';
      const userInfo = window.SuperApp.getters.userInfo()
      return userInfo.email
        && !window.SuperApp.actions.isEmpty(userInfo.mobile)
        && userInfo.country_id
        && userInfo.state_id
        && userInfo.city_id
        && !force_subscribe
    }
    return true;
  },

  isShopDetailValid() {
    if (window.SuperApp.getters.isStore()) {
      const userInfo = window.SuperApp.getters.userInfo()
      const force_subscribe = localStorage.getItem('shopforce_subscribe') === 'true';

      return userInfo.store_type_id
        && userInfo.store_name
        && userInfo.email
        && userInfo.store_location
        && userInfo.country_id
        && userInfo.state_id
        && userInfo.city_id
        && userInfo.store_zipcode
        && userInfo.contact_number
        && !force_subscribe
    }
    return true;
  },
  
  isProviderDetailValid() {
    if (window.SuperApp.getters.isProvider() && !window.SuperApp.getters.isAgent()) {
      const userInfo = window.SuperApp.getters.userInfo()
      const force_subscribe = localStorage.getItem('providerforce_subscribe') === 'true';

      return userInfo.company_name
        && userInfo.first_name
        && userInfo.last_name
        && userInfo.email
        && userInfo.current_location
        && userInfo.country_id
        && userInfo.state_id
        && userInfo.city_id
        && userInfo.mobile
        && !force_subscribe
    }
    return true;
  },
  numberOnly: $event => {
    const keyCode = $event.keyCode ? $event.keyCode : $event.which
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      $event.preventDefault()
    }
  },
  appLimitDecimal: $event => {
    const keyCode = $event.keyCode ? $event.keyCode : $event.which
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      $event.preventDefault()
    }
    if (!/^[\d]+(?:\.\d{1,3})?$/.test($event.target.value)) {
      $event.preventDefault()
    }
  },
  inViewPort: els => {
    const matches = []
    const elCt = els.length

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < elCt; ++i) {
      const el = els[i]
      const b = el.getBoundingClientRect()
      let c

      if (
        b.width > 0
        && b.height > 0
        && b.left + b.width > 0
        && b.right - b.width < window.outerWidth
        && b.top + b.height > 0
        && b.bottom - b.width < window.outerHeight
        // eslint-disable-next-line no-cond-assign
        && (c = window.getComputedStyle(el))
        && c.getPropertyValue('visibility') === 'visible'
        && c.getPropertyValue('opacity') !== 'none'
      ) {
        matches.push(el)
      }
    }
    return matches
  },
  anyErrorInModal: (checkModal = true) => document.querySelectorAll(checkModal ? '.modal-body .is-invalid' : '.is-invalid').length > 0,
}
window.SuperApp.helper = {
  getUserDetailFromIP: () => new Promise(resolve => {
    fetch('http://ip-api.com/json').then(res => res.json()).then(res => resolve(res))
  }),
  copyUrl: url => {
    const textArea = document.createElement('textarea')
    textArea.style.position = 'fixed'
    textArea.style.top = 0
    textArea.style.left = 0
    textArea.style.width = '2em'
    textArea.style.height = '2em'
    textArea.style.padding = 0
    textArea.style.border = 'none'
    textArea.style.outline = 'none'
    textArea.style.boxShadow = 'none'
    textArea.style.background = 'transparent'
    textArea.value = url || window.location.href

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    const copied = document.execCommand('copy')
    document.body.removeChild(textArea)
    return copied
  },
  resize: () => {
    window.dispatchEvent(new Event('resize'))
  },
  navigateTo: (lat, long) => {
    // If it's an iPhone..
    if (navigator.platform.indexOf('iPhone') !== -1 || navigator.platform.indexOf('iPod') !== -1) {
      const iOSversion = () => {
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
          // supports iOS 2.0 and later
          const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
          return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)]
        }
        return [0]
      }
      const ver = iOSversion() || [0]

      let protocol = 'http://'
      if (ver[0] >= 6) {
        protocol = 'maps://'
      }
      window.location = `${protocol}maps.apple.com/maps?daddr=${lat},${long}&amp;ll=`
    } else {
      window.open(`http://maps.google.com?daddr=${lat},${long}&amp;ll=`)
    }
  },
  closeNavigation: () => {
    if (document.querySelector('span.leaflet-routing-collapse-btn')) {
      const parentClassList = document.querySelector('span.leaflet-routing-collapse-btn').parentElement
      let canClick = true
      if (parentClassList && parentClassList.classList) {
        canClick = Array.from(parentClassList.classList).filter(x => x === 'leaflet-routing-container-hide').length === 0
      }
      if (canClick) {
        document.querySelector('span.leaflet-routing-collapse-btn').click()
      }
    }
  },
  scrollTo: id => {
    const currentDom = document.getElementById(id)
    if (currentDom) {
      currentDom.scrollIntoView({
        behavior: 'smooth',
      })
    }
  },
  isNotificationGranted: () => Notification.permission === 'granted',
  requestPermission: async () => {
    if (!window.SuperApp.helper.isNotificationGranted()) {
      await Notification.requestPermission()
    }
  },
  getParams: key => {
    const urlParams = new URL(window.location.href)
    return urlParams.searchParams.get(key)
  },
  setMobileView: () => {
    const val = window.innerWidth
    if (document.querySelector('.horizontal-layout')) {
      if (val <= 767) {
        document.querySelector('.horizontal-layout').classList.add('mobile-view-class')
      } else {
        document.querySelector('.horizontal-layout').classList.remove('mobile-view-class')
      }
    }
  },
  zoomOut: e => {
    if (e && e.currentTarget) {
      e.currentTarget.style.backgroundImage = null
    }
  },
  zoomMagic: e => {
    const zoomEnabled = false
    if (e.currentTarget.children[0] && !e.currentTarget.id && window.MagicZoom && zoomEnabled) {
      e.currentTarget.classList.add('MagicZoom')
      e.currentTarget.setAttribute('href', e.currentTarget.children[0].src)
      e.currentTarget.id = `dynamic_id_${Math.ceil(Math.random() * 10000000)}_${new Date().valueOf()}_app`
      const { id } = e.currentTarget
      setTimeout(() => {
        if (window.MagicZoom) {
          window.MagicZoom.start(id)
        }
      }, 50)
    }
  },
  zoom: e => {
    const zoomer = e.currentTarget
    let offsetX = 0
    let offsetY = 0
    if (e.currentTarget.children[0]) {
      e.currentTarget.style.backgroundImage = `url(${e.currentTarget.children[0].src})`
    }
    if (e.offsetX) {
      offsetX = e.offsetX
    } else if (e.touches && e.touches[0]) {
      offsetX = e.touches[0].pageX
    }
    if (e.offsetY) {
      offsetY = e.offsetY
    } else if (e.touches && e.touches[0]) {
      offsetY = e.touches[0].pageY
    }
    const x = (offsetX / zoomer.offsetWidth) * 100
    const y = (offsetY / zoomer.offsetHeight) * 100
    zoomer.style.backgroundPosition = `${x}% ${y}%`
    zoomer.style.backgroundRepeat = 'no-repeat'
  },
  image: url => {
    if (!url) return window.SuperApp.constants.noImageAvailable
    if (Array.isArray(url)) {
      return url
    }
    if (typeof url === 'object') return url
    let imageUrl = url.replace(/getsuperapp/g, 'momitnow')
    if (jwtDefaultConfig.baseEndpoint.includes('stagingapi')) {
      imageUrl = imageUrl.replace(/devapi/g, 'stagingapi')
    } else if (jwtDefaultConfig.baseEndpoint.includes('devapi')) {
      imageUrl = imageUrl.replace(/stagingapi/g, 'devapi')
    }
    if (!imageUrl.startsWith('http') && imageUrl.startsWith('/storage')) {
      imageUrl = `${jwtDefaultConfig.baseEndpoint}${imageUrl}`
    }
    return imageUrl
  },
  appendCSS: (url, id) => {
    if (!document.getElementById(id)) {
      const element = document.createElement('link')
      if (id) element.setAttribute('id', id)
      element.setAttribute('rel', 'stylesheet')
      element.setAttribute('type', 'text/css')
      element.setAttribute('href', url)
      document.getElementsByTagName('head')[0].appendChild(element)
    }
  },
  appendJS: (url, id) => {
    if (!document.getElementById(id)) {
      const element = document.createElement('script')
      if (id) element.setAttribute('id', id)
      element.setAttribute('type', 'text/javascript')
      element.setAttribute('src', url)
      document.getElementsByTagName('head')[0].appendChild(element)
    }
  },
  removeExternalFile: id => {
    const element = document.getElementById(id)
    if (element) {
      element.remove()
    }
  },
  withBaseUrl: url => {
    if (!url) return false
    if (typeof url !== 'string') return true
    if (url.startsWith('data:')) return true
    return false
  },
  error: $event => {
    // eslint-disable-next-line no-param-reassign
    $event.target.src = window.SuperApp.constants.noImageAvailable
  },
  setCountryCode: (ref, code, contactNumber) => {
    if (ref) {
      // eslint-disable-next-line eqeqeq
      const countryCode = ref.codesCountries.find(x => x.dialCode == code)
      if (countryCode) {
        // eslint-disable-next-line no-param-reassign
        ref.countryCode = countryCode.iso2
        // eslint-disable-next-line no-param-reassign
        ref.phoneNumber = contactNumber
      }
    }
  },
  changeTranslation: (ref, locale) => {
    if (ref && ref.$i18n && ref.$i18n.locale !== locale && locale) {
      // eslint-disable-next-line no-param-reassign
      ref.$i18n.locale = locale
      setTimeout(() => {
        ref.$store.commit('appConfig/TOGGLE_RTL', locale === 'ar')
      }, 100)
      if(ref.$route.params.locale){
        ref.$router.push({
          name: ref.$route.name,
          params: {
            ...ref.$route.params,
            locale
          }
        })
        window.location.reload()
      }
    }
  },
  parsedAddress: res => {
    if (res && res.results && res.results[0] && res.results[0].formatted_address) {
      return res.results[0].formatted_address
    }
    return ''
  },
  getFormattedAddress: async latLng => {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&key=AIzaSyCdacZyFc9nU9jm6FUFdtJynEi4VZPX2Hc`)
    const address = response.json()
    return address
  },
}
window.SuperApp.constants = {
  noImageAvailable: `${jwtDefaultConfig.baseEndpoint}/storage/shops/profile/0d1687bb73b9eebf147a56ba4b5eda7a564b5c6b.svg`,
}
window.SuperApp.actions = {
  groupArray(array, f) {
    const groups = {}
    array.forEach(o => {
      const group = JSON.stringify(f(o))
      groups[group] = groups[group] || []
      groups[group].push(o)
    })
    return Object.keys(groups).map(group => groups[group])
  },
  newOrder: () => {
    const audio = new Audio(`${baseURL}/audio/new-order-recieved.wav`)
    audio.play()
  },
  newMessage: () => {
    const audio = new Audio(`${baseURL}/audio/message-tone.mp3`)
    audio.play()
  },
  validate: (selectors = '', type = 'boolean') => {
    if (!selectors) {
      if (type === 'boolean') return false
      return 0
    }
    const notValid = Array.from(document.querySelectorAll(selectors)).filter(item => !item.value)
    if (type === 'boolean') {
      return !notValid.length
    }
    return notValid.length
  },
  addMarker: (latLongs, images, map, markerKeeper = '') => {
    const dataMarkers = []
    latLongs.forEach((y, i) => {
      const latLong = y.split(',').map(x => parseFloat(x.trim()))
      // eslint-disable-next-line no-param-reassign
      latLongs[i] = window.L.latLng(latLong[0], latLong[1])
    })
    latLongs.forEach((item, i) => {
      const marker = window.L.marker(item, {
        draggable: true,
        bounceOnAdd: false,
        bounceOnAddOptions: {
          duration: 1000,
          height: 800,
        },
        icon: window.L.icon({
          iconUrl: images[i],
          iconSize: [35, 35],
          iconAnchor: [10, 29],
          popupAnchor: [0, -29],
        }),
      })
      dataMarkers.push(marker)
      marker.addTo(map)
    })
    if (markerKeeper) {
      window[markerKeeper] = dataMarkers
    }
    // eslint-disable-next-line new-cap
    const group = new window.L.featureGroup(dataMarkers)
    map.fitBounds(group.getBounds())
    map.setZoom(13)
  },
  addCustomMarker: (lat, lng, opts, map) => {
    const dataMarkers = []
    const point = window.L.latLng(lat, lng)
    const marker = window.L.marker(point, opts)
    dataMarkers.push(marker)
    marker.addTo(map)
    const group = new window.L.featureGroup(dataMarkers)
    map.fitBounds(group.getBounds())
    map.setZoom(13)
  },
  createRouting: (latLongs, images, map, routeObj) => {
    latLongs.forEach((y, i) => {
      const latLong = y.split(',').map(x => parseFloat(x.trim()))
      // eslint-disable-next-line no-param-reassign
      latLongs[i] = window.L.latLng(latLong[0], latLong[1])
    })
    if (window[routeObj]) {
      window[routeObj].setWaypoints(latLongs)
    } else {
      window[routeObj] = window.L.Routing.control({
        waypoints: latLongs,
        // lineOptions: {
        //   styles: [{ color: 'blue', opacity: 1, weight: 2 }, { color: 'red', opacity: 1, weight: 2 }],
        // },
        // eslint-disable-next-line no-unused-vars
        createMarker(i, waypoint, n) {
          const marker = window.L.marker(waypoint.latLng, {
            draggable: true,
            bounceOnAdd: false,
            bounceOnAddOptions: {
              duration: 1000,
              height: 800,
            },
            icon: window.L.icon({
              iconUrl: images[i],
              iconSize: [35, 35],
              iconAnchor: [10, 29],
              popupAnchor: [0, -29],
            }),
          })
          return marker
        },
      })
      window[routeObj].addTo(map)
    }
  },
  replaceUnderscore: textContent => {
    if (!textContent) return textContent
    return textContent.replace(/_/g, ' ')
  },
  sharePage: (title = 'Super App', text = '', url = '') => {
    if (!url) {
      // eslint-disable-next-line no-param-reassign
      url = window.location.href
    }
    if (navigator.share) {
      navigator
        .share({
          title,
          text,
          url,
        })
        .then(() => {
          console.log('Thanks for sharing!')
        })
        .catch(err => {
          console.log("Couldn't share because of", err.message)
        })
    } else {
      console.log('web share not supported')
    }
  },
  selectedUserAddress: () => {
    if (localStorage.userSelectedAddress) {
      return +localStorage.userSelectedAddress
    }
    return 0
  },
  selectedCityName: () => localStorage.userSelectedCityName,
  setLandingPageHeight: () => {
    setTimeout(() => {
      if (document.querySelectorAll('.customer-landing-page-header')[0] && document.querySelectorAll('.top-section-6')[0] && document.querySelectorAll('.container.content-info')[0]) {
        const containerLanding = document.querySelectorAll('.customer-landing-page-header')[0].offsetHeight
        const containerSection = document.querySelectorAll('.top-section-6')[0].offsetHeight
        const finalHeight = window.innerHeight - (containerLanding + containerSection) + 90
        document.querySelectorAll('.container.content-info')[0].style.minHeight = `${finalHeight}px`
      }
    }, 0)
  },
  cloneDeep: array => JSON.parse(JSON.stringify(array)),
  isEmpty: value => {
    return (
      value === undefined ||
      typeof value === typeof undefined ||
      value === null ||
      value === 0 ||
      value === "0" ||
      value === "null" ||
      value === "NULL" ||
      value === "Null" ||
      value === false ||
      value === "false" ||
      (typeof value === 'object' && Object.keys(value).length === 0) ||
      (typeof value === 'string' && value.trim().length === 0)
    );
  }
}
window.SuperApp.getters = {
  _isProvider: () => localStorage.getItem('providerType') === 'provider' && localStorage.getItem('providerLoggedIn') == true,
  _isUser: () => {
    const userType = localStorage.getItem('userType')
    const userLoggedIn = localStorage.getItem('userLoggedIn')
    return userType === 'user' && userLoggedIn == 'true'
  },
  _isStore: () => localStorage.getItem('shopType') === 'shop' && localStorage.getItem('shopLoggedIn') == true,
  _isAgent: () => {
    if (localStorage.getItem('providerType') === 'provider' && localStorage.getItem('providerLoggedIn') == true) {
      if(localStorage.getItem('providerData')){
        const data = JSON.parse(localStorage.getItem('providerData'))
        return data?.type === 'AGENT'
      }
    }
    return false
  },
  isUser: () => localStorage.getItem(window.SuperApp.getters.getPrefixStorage('Type')) === 'user',
  isProvider: () => localStorage.getItem(window.SuperApp.getters.getPrefixStorage('Type')) === 'provider',
  isStore: () => localStorage.getItem(window.SuperApp.getters.getPrefixStorage('Type')) === 'shop',
  isAdmin: () => localStorage.getItem(window.SuperApp.getters.getPrefixStorage('Type')) === 'admin',
  userType: () => localStorage.getItem(window.SuperApp.getters.getPrefixStorage('Type')),
  isAgent: () => {
    if (localStorage.getItem(window.SuperApp.getters.getPrefixStorage('Data'))) {
      return JSON.parse(localStorage.getItem(window.SuperApp.getters.getPrefixStorage('Data')))?.type === 'AGENT'
    }
    return false
  },
  userInfo: () => (localStorage.getItem(window.SuperApp.getters.getPrefixStorage('Data')) ? JSON.parse(localStorage.getItem(window.SuperApp.getters.getPrefixStorage('Data'))) : null),
  saltKey: () => 'MQ==',
  userCity: () => localStorage.getItem('userSelectedCityId'),
  userCountry: () => {
    if (localStorage.getItem('userSelectedCountryId') && localStorage.getItem('userSelectedCountryId') !== undefined && localStorage.getItem('userSelectedCountryId') !== 'undefined') {
      // eslint-disable-next-line radix
      return parseInt(localStorage.getItem('userSelectedCountryId'))
    }
    return null
  },
  setLang: lang => {
    localStorage.setItem(window.SuperApp.getters.getPrefixStorage('LangData'), lang)
    localStorage.setItem('locale', lang)
  },
  selectedLang: () => (localStorage.getItem(window.SuperApp.getters.getPrefixStorage('LangData')) ? localStorage.getItem(window.SuperApp.getters.getPrefixStorage('LangData')) : 'en'),
  userLang: () => (window.SuperApp.getters.userInfo() && window.SuperApp.getters.userInfo().language ? window.SuperApp.getters.userInfo().language : 'en'),
  setUrlType: type => {
    localStorage.setItem('urlType', type)
  },
  getUrlType: () => {
    if (window.socketApp && window.socketApp.$route) {
      return window.socketApp.$route.meta.type
    }
    if (window.location.pathname && window.location.pathname.split('/')[1]) {
      return window.location.pathname.split('/')[1]
    }
    return 'user'
  },
  getPrefixStorage: postfix => `${window.SuperApp.getters.getUrlType()}${postfix}`,
  setUserInfo: info => {
    localStorage.setItem(window.SuperApp.getters.getPrefixStorage('Data'), info)
    window.SuperApp.getters.setForceSubscribe(JSON.parse(info)?.force_subscribe)
  },
  setForceSubscribe: force_subscribe => {
    localStorage.setItem(window.SuperApp.getters.getPrefixStorage('force_subscribe'), force_subscribe == 1)
  },
  setUserLoggedIn: logged => {
    localStorage.setItem(window.SuperApp.getters.getPrefixStorage('LoggedIn'), logged)
  },
  setUserType: type => {
    localStorage.setItem(window.SuperApp.getters.getPrefixStorage('Type'), type)
  },
  setUserAccess: access => {
    localStorage.setItem(window.SuperApp.getters.getPrefixStorage('Access'), access)
  },
  removeUserInfo: () => {
    localStorage.removeItem(window.SuperApp.getters.getPrefixStorage('Data'))
  },
  removeUserAccess: () => {
    localStorage.removeItem(window.SuperApp.getters.getPrefixStorage('Access'))
  },
  storageTokenKeyName: () => localStorage.getItem(window.SuperApp.getters.getPrefixStorage('TokenKeyName')),
  storageRefreshTokenKeyName: () => localStorage.getItem(window.SuperApp.getters.getPrefixStorage('RefreshTokenKeyName')),
}

window.SuperApp.appConstants = {
  languageList: () => [
    {
      text: 'English',
      value: 'en',
    },
    {
      text: 'Arabic',
      value: 'ar',
    },
    {
      text: 'Hindi',
      value: 'hi',
    },
    {
      text: 'German',
      value: 'de',
    },
    {
      text: 'Spanish',
      value: 'es',
    },
    {
      text: 'French',
      value: 'fr',
    },
    {
      text: 'Russian',
      value: 'ru',
    },
    {
      text: 'Uzbek',
      value: 'uz',
    },
  ],
  statusList: () => [
    {
      text: 'All',
      value: '',
    },
    {
      text: 'Active',
      value: '1',
    },
    {
      text: 'Inactive',
      value: '0',
    },
  ],
  filterStatusList: () => [
    {
      text: 'Show All',
      value: '',
    },
    {
      text: 'Active',
      value: '1',
    },
    {
      text: 'Inactive',
      value: '0',
    },
  ],
}
window.addEventListener('resize', () => {
  window.SuperApp.helper.setMobileView()
})
